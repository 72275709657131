import React from 'react';
import { Link, graphql } from 'gatsby';

import Image from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import { rhythm } from '../utils/typography';

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={['blog', 'gatsby', 'javascript', 'react']}
        />

        <Image
          style={{
            height: '40vh',
            marginBottom: "10px"
          }}
          imgStyle={{objectPosition: "top"}}
          fluid={data.mapleGreeting.childImageSharp.fluid}
          alt=""
        />

        <h1>
          Hello Friends
          {' '}
          <span role="img" aria-label="pig emoji">
            🐷
          </span>
        </h1>
        <p>Welcome to our new blog!</p>
        <p>
          We are still working on getting things setup so make sure to bookmark us and check back soon!
        </p>
        <p>Please excuse the ugliness!</p>
        <Link to="/blog/">
          <Button marginTop="35px">Check it out</Button>
        </Link>
      </Layout>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    mapleGreeting: file(absolutePath: { regex: "/maple-hi-there.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }`;
